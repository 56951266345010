<template>
  <div class="news">
    <div class="title">{{news.title}}</div>
    <div class="time">{{news.author}}</div>
    <div class="cnt" v-html="news.content"></div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      news:{},
      time:null
    }
  },
  methods:{
    getNews(){
      this.$http.post('/get_token')
      .then( res =>{
        if(res.data.code == 200){
          let temp = res.data.result.find(obj => obj.article_id == this.$route.query.theNews)?.content.news_item[0];
          temp.content = temp.content.replace(/data-src/g,'src');
          this.news = temp;
          console.log(temp)
        }
        
      })
    },
    replImgs(){ 
      
      
    }
  },
  created(){
    // console.log(this.$route.query.theNews)
    // this.getNews()

  },
  mounted(){
    // this.allImgs()
    this.$nextTick()
    this.getNews()
    
  }
}
</script>
<style lang="scss">
  .news{
    width: 100%;
    max-width: 677px;
    margin: 0 auto;
    padding: 20px;
    .title{
      font-size: 22px;
      margin-bottom: 14px;
    }
    .time{
      margin-bottom: 22px;
      font-size: 15px;
      color: gray;
    }
    .cnt{
      width: 100%;
      img {
        width: 100%;
        // content:attr(data-src)
      }
    }
    
  }
</style>